@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,800&display=swap);
*{
  margin: 0;
  box-sizing: border-box;
  
}

body{
  background-color: #221E3F;
  
}

.header-container{
  width: 100vw;
  height: 110vh;
  background-color: #221E3F;
  z-index: -6;
}
.medidas-img{
  width: 100vw;
}

.medida-full{
  width: 100vw;
  height: 100vh;
}

.circle-container{
  z-index: 3;
  position: absolute;
  top: -9%;
  overflow: hidden;
}

.p-container-data-circle{
  width: 100%;
  position: relative;
  top: -190px;
  z-index: 5;

}

.z-index10{
  z-index: 150;
}

.p-2-data-circle{
  position: relative;
  left: 60px;
  top: -120px;
}

.p-3-data-circle{
  position: relative;
  left:-50px;
  top: -40px;
  z-index: -1;
}

.flex-center{
  justify-content: center;
}

.flex-center-2{
  align-items: center;
}

.circle-data-blue{
  width: 246.84px;
  height: 246.84px;
  background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
  border-radius: 50%;
}

.circle-data-purplue{
  width: 246.84px;
  height: 246.84px;
  background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%); 
  border-radius: 50%;
  position: relative;
}

.title-circle-data{
font-family: Poppins;
font-style: normal;
font-weight: 800;
font-size: 80px;
line-height:63px;
text-align: center;
color: #FFFFFF;
}

.subtitle-circle-data{
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height:23px;
text-align: center;
color: #FFFFFF;
}

.principal-circle{
  position: absolute;
  left: 10%;
  width: 80vw;
  height: 80vh;
  background: linear-gradient(82.63deg, #6756CC -3.1%, #CD5BBA 44.68%, #FAC079 97.52%);
  -webkit-clip-path: circle(50% at 50% 100%);
          clip-path: circle(50% at 50% 100%);
  -webkit-filter: blur(150px);
          filter: blur(150px);
}


.circle-1{
  z-index: 4;
  position: absolute;
  left: 63%;
  bottom: 65%;
  width: 118px;
  height: 118px;
  background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
  -webkit-transform: rotate(-14.45deg);
          transform: rotate(-14.45deg);
  border-radius: 50%;
}

.circle-2{
  z-index: 4;
  position: absolute;
  left: 10%;
  bottom: 40%;
  width: 90.45px;
  height: 90.45px;
  background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
  -webkit-transform: rotate(144.17deg);
          transform: rotate(144.17deg);
  border-radius: 50%;
}

.circle-3{
  z-index: 4;
  position: absolute;
  left: 5%;
  bottom: 75%;
  width: 49.05px;
  height: 49.05px;
  background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
  -webkit-transform: rotate(-14.45deg);
          transform: rotate(-14.45deg);
  border-radius: 50%;
}

.circle-4{
  z-index: 4;
  position: absolute;
  left: 85%;
  bottom: 45%;
  width: 43px;
  height: 43px;
  background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
  -webkit-transform: rotate(144.17deg);
          transform: rotate(144.17deg);
  border-radius: 50%;
}

.circle-5{
  z-index: 4;
  position: absolute;
  left: 89%;
  bottom: 75%;
  width: 123px;
  height: 123px;
  background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
  -webkit-transform: rotate(144.17deg);
          transform: rotate(144.17deg);
  border-radius: 50%;
}

.navbar-container{
  width: 100vw;
  z-index: 10000;
  padding-top: 1%;
  position: fixed;
}

.background-scroll{
  z-index: 10000;
  background: rgba(73, 73, 73, 0.19);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.27 );
  backdrop-filter: blur( 8.5px );
  -webkit-backdrop-filter: blur( 8.5px );
  height: 6.6%;
  transition:  0.5s all ease-in-out ;
}

.p-relative{
  position: relative;
  top: 0px; left: 0px;
}

.p-absolute{
  position: absolute;
  bottom: 0vh;
}

.z-index-10{
  z-index: -10;
}

.poppins-font{
  font-family: 'Poppins', sans-serif;
}

.flex{
  display: flex;
}

.flex-column{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button{
  width: 145px;
  height: 45px;
  background: #02C6F8;
  border-radius: 50px;
  border: none;
  color: white;
  font-weight: 600;
  z-index: 5;
  font-size: 17px;
}

.text-decoration-none{
  text-decoration: none;
  color: white;
}

.social-media-container{
  width: 100vw;
  height: 85px;
  background-color:rgb(30, 26, 56);
  padding: 0px 40px 10px;
  color: white;
  font-weight: 200;
  font-size: 15px;
}

.text-slogan-container{
  z-index: 30;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.txt-slogan{
  z-index: 33;
  font-size: 87px;
  line-height: 99px;
  color: white;
  font-weight: 400;
  width: 1106px;
  height:244px;
  text-align: center;
  text-shadow: 1.5px 1.5px 3px rgb(91, 5, 99,0.1);
}

.span-slogan{
  z-index: 30;
  font-weight: 800;
}

.team-personas{
  width: 371px;
  height: 571px;
  background: #6E61C6;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.img-team-persona{
  width: 100%;
  overflow: hidden;
  opacity: 0;
  
}

.img-team-persona:hover{
  opacity: 1;
  position: absolute;
}

.p-text-team{
  width: 100%;
  position:absolute;
  top: 0;
}

.sub-title-personas-teams{
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
}

.par-personas-teams{
  text-align: center;
  font-weight: 100;
  font-size: 16px;
  line-height: 23px;
  padding-left: 8px;
  padding-right: 8px;
}
.coworkers{
  font-size: 100px;
}

.navbar{
  list-style-type: none;
}

.navbar-options{
  margin-left: 70px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.color-white{
  color: white;
}

.select{
  background: none;
  border: none;
  margin-bottom: 4px;
}

.size-18px{
  font-size: 18px;
}

.navbar-options:hover{
  cursor: pointer;
  color: #02C6F8;
}

.button:hover{
  cursor: pointer;
  background-color: white;
  color: #221E3F;
}

a:hover{
  color: #221E3F;
}

.justify-content-around{
  justify-content:space-around;
}

.justify-content-evenly{
  justify-content:space-evenly;
}

.justify-content-btw{
  justify-content:space-between;
}


.margin-top-150{
  margin-top: 150px;
}

.margin-top-320{
  margin-top: 520px;
}

.margin-bottom-100{
  margin-bottom: 150px;
}

.btn-video{
  position: relative;
  top: 234px;
  z-index: 3;
}

.borar{
  width:800px;
  height: 1180px;
  background-image: linear-gradient(to top , rgba(34, 30, 63), rgba(34, 30, 63),rgba(34, 30, 63), rgba(34, 30, 63, 0.7), rgba(34, 30, 63, 0.1)) ;
  position: relative;
  top:0px;
}

.circle-about{
  z-index: 10;
  position: absolute;
  left: 0;
  top:0;
}

.about-us-container{
  width: 100vw;
  height: 200vh;
  background-color:#221E3F;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top:15%
}

.about-us-text-containter{
  width: 100%;
  height: 100%;
  align-items: center;
}

.about-us-paragraph-container{
  width: 100vw;
}

.about-us-paragraph{
  width: 100%;
  height: 100%;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 16px;
  line-height: 35px;
  margin-bottom: 120px;
}

.title-section {
  font-size: 60px;
  line-height: 42px;
  font-weight: 600;
}

.color-white{
  color: white;
}

.card-services-container{
  z-index: 30;
  width: 349px;
  background-image: linear-gradient(to top, #6960C5,#6960C5,#6960c5, #B671CF );
  border-radius: 26px;
  padding: 23px;
  margin-top: 25px;
}

.whyus-container{
  width: 100VW;
  height: 100%;
  background: white;
}

.p-text-container{
  width: 714px;
  height: 157px;
}

.why-text-container{
  width: 100%;
  padding-top: 20%;
}

.color-why{
  width: 100vw;
  min-height: 693.3px;
  background: linear-gradient(180deg, #221E3F 13.02%, rgba(111, 98, 199, 0.88) 59.37%, #FFFFFF 100%);
}

.pro-containers{
  width: 100%;
  margin-top: 80px;
  align-items: flex-start;
}

.pro-title-circle{
  font-size: 18px;
  line-height: 26px;
}

.img-container{
  margin-top: 90px;
}

.pro-circle{
  min-width: 198px;
  height: 198px;
  border: 4px solid #6E61C6;
  border-radius: 50%;
  cursor:pointer;
  position: relative;
}

.pro-img{
  position: absolute;
}

.subtitle-section{
  font-weight: 100;
  font-size: 45px;
}

.c-infraestructure-container{
  width: 100vw;
  display: flex;
  overflow-x: scroll;
}

.capa{
  position: absolute;
  top:0;
  min-width: 197px;
  min-height: 198px;
  background:#6E61C6;
  border-radius: 50%;
  text-align: center;
  opacity: 0;
}
.capa:hover{
  opacity: 1;
}

.capa-2{
  opacity: 0;
}

.font-text-chico{
  font-size: 14.5px;
  line-height: 20px;
  max-width: 198px;
  margin-left: 5px;
  margin-right: 5px;
  
}

.offices-container{
  margin-top: 300px;
  padding-top: 180px;
  width: 100vw;
  height: 100%;
  background: #6F62C7;
}

.infraestructure-container{
  min-width: 434px;
  min-height: 323px;
  background: #221E3F;
}

.infraestructure-container-2{
  min-width: 434px;
  min-height: 323px;
  background: #6F62C7;
}

.services-desktop-container{
  width: 100vw;
  height: 150vh;
  background-color:#221E3F;
  padding: 70px 0 ;
}

.title-card{
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  color: #FFFFFF;
  margin-top:36px ;
  text-align: center;
}

.paragraph-card{
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color:white;
  margin-top:23px ;
  text-align: center;
}

.icon-card{
  margin-top: 10px ;
  height:125px;
}

.margin-top-15{
  margin-top: 15px;
}

.margin-top-75{
  margin-top: 55px;
}

.industries-containers{
  max-width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
}

.industrie-containter-item-1{
  padding-top: 30px;
  min-width: 650px;
  height: 300px;
  border-radius: 22px;
  background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
}

.p-industrie{
  margin: 30px 30px 30px 30px;
}

.bold-txt{
  font-style: bold;
}

.industrie-containter-item-2{
  padding-top: 30px;
  min-width: 650px;
  height: 300px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 22px;
  background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
}

.service-container{
  width: 100vw;
  height: 1630px;
  justify-content: center;
}

.margin-right-24px{
  margin-right: 24px;
  align-self: flex-start;
}

.margin-bottom-55{
  margin-bottom: 55px;
}

.margin-bottom-15{
  margin-bottom: 25px;
}

.margin-left-15{
  margin-left: 15px;
}

.contact-us-container{
  width: 100vw;
  height: 900px;
  background: #6F62C7;
}
.contact-us-container select option{
  color: #FFFFFF;
  background: #2b2b2b;
}

.input-container{
  width: 614px;
  height: 100%;
  flex-direction: column;
}

.input-mail{
  background:none;
  border: none;
  outline: none;
  border-bottom: 1px solid #00BDF9;
  margin-bottom: 20px;
  margin-left: 30px;
  color: rgba(255, 255, 255);
}

.input-a-size{
  width: 303px;
  height: 42px;
}

.input-b-size{
  width: 635px;
  height: 42px;
}


.input-message{
  width: 635px;
  height: 251px;
  border: 1px solid #00BDF9;
  background:none;
  border-radius: 25px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-top: 40px;
  text-align: center;
  color: white;
  padding: 20px;
}

.justify-content-center{
  justify-content: center;
}

.align-items-center{
  align-items: center;
}

.btn-countries {
  font-weight: 500;
  font-size: 25px;
  background: none;
  border: none;
  display: inline;
}

.btn-countries:hover{
  cursor: pointer;
  color: #00BDF9;
}

.btn-countries:focus{
  cursor: pointer;
  color: white;
  border-bottom: 5px solid #00BDF9;
}

.align-items-end{
  align-items: flex-end;
}

.address-text{
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-left: 10px;
}

.icon-address{
  width: 20px;
  height: 20px;
}

input::-webkit-input-placeholder {
  color:white;
}

input:-ms-input-placeholder {
  color:white;
}

input::placeholder {
  color:white;
}

.input-message::-webkit-input-placeholder {
  color:white;
}

.input-message:-ms-input-placeholder {
  color:white;
}

.input-message::placeholder {
  color:white;
}

@media  (max-width: 700px) {

  .medida-full{
    width: 100vw;
    height: 100vh;
  }
  
  .circle-container{
    z-index: 3;
    position: absolute;
    overflow: hidden;
  }
  .p-2-data-circle{
    position: relative;
    left: 70px;
    top: -100px;
  }
  .p-3-data-circle{
    position: relative;
    left:-40px;
    top: -80px;
    z-index: -1;
  }

  .circle-animated-containter{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .circle-1{
    z-index: 4;
    position: absolute;
    left: 63%;
    bottom: 65%;
    width: 60px;
    height: 60px;
    background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
    -webkit-transform: rotate(-14.45deg);
            transform: rotate(-14.45deg);
    border-radius: 50%;
    -webkit-animation: c1 25s ease-in-out infinite;
            animation: c1 25s ease-in-out infinite;
  }
  
  .circle-2{
    z-index: 4;
    position: absolute;
    left: 40%;
    bottom: 80%;
    width: 20px;
    height: 20px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    -webkit-transform: rotate(144.17deg);
            transform: rotate(144.17deg);
    border-radius: 50%;
    -webkit-animation: c2 15s ease-in-out infinite;
            animation: c2 15s ease-in-out infinite;
  }

  .circle-3{
    z-index: 4;
    position: absolute;
    left: 5%;
    bottom: 85%;
    width: 20px;
    height:20px;
    background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
    -webkit-transform: rotate(-14.45deg);
            transform: rotate(-14.45deg);
    border-radius: 50%;
  
  }
  
  .circle-4{
    z-index: 4;
    position: absolute;
    left: 95%;
    bottom: 65%;
    width: 23px;
    height: 23px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    -webkit-transform: rotate(144.17deg);
            transform: rotate(144.17deg);
    border-radius: 50%;

  }
  .circle-4{
    z-index: 4;
    position: absolute;
    left: 85%;
    bottom: 85%;
    width: 13px;
    height: 13px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    -webkit-transform: rotate(144.17deg);
            transform: rotate(144.17deg);
    border-radius: 50%;
  }

  .circle-5{
    z-index: 4;
    position: absolute;
    left: 9%;
    bottom: 75%;
    width: 20px;
    height: 20px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    -webkit-transform: rotate(144.17deg);
            transform: rotate(144.17deg);
    border-radius: 50%;
  }

  .container-img-team{
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .p-container-data-circle{
    width: 100vw;
    position: absolute;
    top:70%;
    z-index: 5;
  }

  .pro-circle{
    min-width: 197px;
    height: 198px;
    border: 4px solid #6E61C6;
    border-radius: 50%;
    cursor:pointer;
  }  

  .img-about{
    width: 40%;
    position: absolute;
    left: 0;
    top:20px;
  }

  .color-why{
    width: 100vw;
    height: 193.3px;
    background: linear-gradient(180deg, #221E3F 13.02%, rgba(111, 98, 199, 0.88) 59.37%, #FFFFFF 100%);
    
  }

  .offices-container{
    margin-top: 150px;
    padding-top: 80px;
    width: 100vw;
    height: 100%;
    background: #6F62C7;
  }

.why-text-container{
  width: 100%;
  text-align: center;
}

.medidas-img{
  width: 100vw;
}

.subtitle-section{
  font-weight: 100;
  font-size: 30px;
  text-align: center;
}
.title-section.margin-top-75.margin-bottom-55.poppins-font{
  margin-top: 130px;
  margin-bottom: 30px;
}

  .pro-containers{
    width: 100vw;
    margin-top: 80px;
    flex-direction: column;
    align-items: center;
  }

.whyus-container{
  width: 100vw;
  background: white;

}

.p-text-container{
  width: 100vw;
  height: 157px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
}

.why-text-container{
  width: 100vw;
}

.industries-containers{
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 4vw;
  padding-right: 4vw;
}

  .circle-data-purplue{
    width:120px;
    height:120px;
    background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%); 
    border-radius: 50%;
    position: relative;
  }

  .circle-data-blue{
    width: 120px;
    height: 120px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    border-radius: 50%;
  }

  .industrie-containter-item-1{
    padding-top: 30px;
    min-width: 350px;
    height: 430px;
    border-radius: 22px;
    background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
  }


.industrie-containter-item-2{
  padding-top: 30px;
  min-width: 350px;
  height: 430px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 22px;
  background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
}

  .title-circle-data{
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height:33px;
    text-align: center;
    color: #FFFFFF;
    }
    
    .subtitle-circle-data{
    font-family: Poppins;
    font-style: normal;
    font-size: 10px;
    line-height:13px;
    text-align: center;
    color: #FFFFFF;
 
    }

  .btn-menu{
    background: none;
    border: none;
    position: absolute;
    left: 85%;
    z-index: 1;
    padding-top: 3px;
  }
  
  .navbar-container{
    justify-content: space-between;
    padding-left: 13px;
    padding-right: 20%;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 7%;
  }

  .option-menu-container{
    position: absolute;
    padding: 20% 0% 23%;
    left: 0px;
    width: 100vw;
    height: 50vh;
    background: rgba( 0, 0, 0, 0.7 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    -webkit-clip-path: circle(80.0% at 50% 0);
            clip-path: circle(80.0% at 50% 0);
    top: -60px;
  }


  .navbar-options{
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    font-size: 17px;
  }
  
  .background-scroll{
    background: rgba(73, 73, 73, 0.19);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.27 );
    backdrop-filter: blur( 8.5px );
    -webkit-backdrop-filter: blur( 8.5px );
    width: 100%;
    height: 7%;
    position: fixed;
    transition:  0.5s all ease-in-out ;
  }

  .flex.navbar.poppins-font{
    padding-right: 10%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .text-slogan-container{
    z-index: 30;
    width: 100vw;
    height: 50vh;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 5%;
  }

  .txt-slogan{
    font-size: 41px;
    line-height: 50px;
    color: white;
    font-weight: 400;
    width: 290px;
    height: 174px;
    text-align: center;
  }
  
  .about-us-container{
    width: 100vw;
    height: 3203px;
    background-color:#221E3F;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .flex.about-us-text-containter{
    flex-direction: column;
    margin-top: 5%;
    height: 1500px;
  }

  .circle-about{
    z-index:2;
    position: absolute;
    left: 0;
    top:-5%;
  }

  .about-us-container-title{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    font-weight: 800;
    margin-top:-5%;
    margin-left: 0;
  }

  .title-section {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  .about-us-paragraph{
    width: 100vw;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    margin-top: 35%;
  }
  
  .p-absolute{
    position: absolute;
    bottom: 10vh;
  }

  .input-container{
    width: 100vw;
    height: 100%;
    flex-direction: column;
  }

  .contact-us-container{
    width: 100vw;
    height: 100%;
  }

  .color-white{
    color: white;
  }

  input::-webkit-input-placeholder {
    color:white;
  }

  input:-ms-input-placeholder {
    color:white;
  }

  input::placeholder {
    color:white;
  }

  .input-message::-webkit-input-placeholder {
    color:white;
  }

  .input-message:-ms-input-placeholder {
    color:white;
  }

  .input-message::placeholder {
    color:white;
  }

  .input-mail{
    background:none;
    border: none;
    outline: none;
    border-bottom: 1px solid #00BDF9;
    margin-bottom: 20px;
    color: rgba(255, 255, 255);
  }
  
  .input-a-size{
    width: 90vw;
    height: 42px;
  }
  
  .input-b-size{
    width: 90vw;
    height: 42px;
  }
  
  .input-message{
    width: 90vw;
    height: 251px;
    border: 1px solid #00BDF9;
    background:none;
    border-radius: 25px;
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center;
  }

  .contact-us-container .flex.justify-content-center{
    display: flex;
    flex-direction: column;
  }

  .input-container .flex{
    flex-direction: column;
  }
  .flex.input-container.align-items-end{
    align-items: center;
  }

  .flex .input-mail{
    margin-left: 0px;
    margin-top: 20px;
  }

  .flex .input-message{
    margin-left: 0px;
  }

  .btn-countries {
    font-weight: 500;
    font-size: 15px;
    background: none;
    border: none;
  }

  .address-text{
    font-size: 12px;
    font-weight: 400;
    color: white;
  }

  .icon-address{
    width: 16px;
    height: 16px;
  }

  .address-container{
    margin-top: 20px;
    width: 91vw;
    height: 100px;
    border: 0.5px solid #02C6F8;
    border-radius: 19px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .btn-countries:focus{
    cursor: pointer;
    color: white;
    border-bottom: 2px solid #00BDF9;
  }

  .flex.align-items-center.margin-top-15{
    flex-direction: row;
    padding-left: 13px;
  }

  .btn-countries{
    padding-right:5px;
    font-size: 15.5px;
  }
  .margin-bottom-15{
    margin-bottom: 0px;
  }

  .button{
    width: 119px;
    height: 42px;
    background: #02C6F8;
    border-radius: 50px;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 15px;
    z-index: 123;
    margin-bottom: 90px;
    
  }

  .btn-countries-container{
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card-services-container{
    width: 95vw;
    height: 100%;
    background-image: linear-gradient(to top, #6960C5,#6960C5,#6960c5, #B671CF );
    border-radius: 26px;
  }

  .services-mobile-container{
    width: 100vw;
    height: 90%;
    background-color:#221E3F;
  }
  
  .paragraph-card{
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color:white;
    margin-top:23px ;
    text-align: center;
  }

  .btn-show-more{
    background: none;
    border: none;
    color: white;
    margin-top: 13px;
    font-size: 15px;
    font-weight: 700;
  }

  .title-card{
    font-size: 27px;
  }

  .services-totalcard-container{
    flex-direction: column;
  }

  .social-media-container{
    padding: 0px 15px 0px;
    font-size: 11px;
    width: 100%;
    height: 100px;
  }
  
  .contact-us-container{
    width: 100%;
    height: 100%;
  }
  .pro-containers{
    width: 100%;
    margin-top: 80px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .container-img-team{
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }
  .team-personas{
    margin-top: 20px;
  }

  .container-img-team{
    width: 100vw;
    height: 100%;
    flex-direction: column;
    align-items: center;

  }

  .coworkers{
    font-size: 40px;
  }
  

}

@media (min-width: 1051px) and  (max-width: 1251px){

  .flex.about-us-text-containter{
    flex-direction: column;
  } 
  
  .why-text-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .circle-about{
    z-index:2;
    position: absolute;
    left: 0;
    top:-5%;
  }

  .about-us-container-title{
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    font-weight: 800;
    margin-top:10%;
    margin-left: 0;
  }

  .title-section {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  .about-us-paragraph{
    z-index:30;
    width: 100vw;
    height: 100vh;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    margin-top: 35%;
    margin-bottom: 10%;
  }
  
  .text-slogan-container{
    z-index: 30;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20%;
  }
  .services-totalcard-container{
    width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-show-more{
    background: none;
    border: none;
    color: white;
    margin-top: 13px;
    font-weight: 700;
  }
  .btn-menu{
    background: none;
    border: none;
    position: absolute;
    left: 89%;
    z-index: 1;
    padding-top: 3px;
  }
  .pro-containers{
    width: 100vw;
    margin-top: 80px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-services-container{
    margin-left: 15px;
    margin-right: 15px;
  }
  .input-container{
    width: 45vw;
    height: 100%;
    flex-direction: column;
  }
  contact-us-container{
    width: 100vw;
    height: 1050px;
    padding-bottom: 55px;
  }

  .input-mail{
    background:none;
    border: none;
    outline: none;
    border-bottom: 1px solid #00BDF9;
    margin-bottom: 20px;
    color: rgba(255, 255, 255);
    margin-right: 24px;
  }
  
  .input-message{
    width: 639px; 
    height: 251px;
    border: 1px solid #00BDF9;
    background:none;
    border-radius: 25px;
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center;
    margin-right: 24px;
  }

  .contact-us-container .flex.justify-content-center{
    display: flex;
    flex-direction: column;
  }

  .flex.input-container.align-items-end{
    align-items: center;
  }

  .flex .input-mail{
    margin-left: 0px;
    margin-top: 20px;
  }

  .flex .input-message{
    margin-left: 0px;
  }

  .btn-countries {
    font-weight: 500;
    font-size: 26px;
    background: none;
    border: none;
  }
  .btn-countries-container{
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .about-us-container-title{
    font-size: 90px;
    line-height: 100px;
    text-align: center;
    font-weight: 800;
    margin-top:40%;
    margin-left: 0;
  }

  .title-section {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  .about-us-paragraph{
    z-index: 20;
    width: 100vw;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 15px;
    line-height: 35px;
    text-align: center;
    margin-top: 15%;
    margin-bottom: 10%;
  }

  .flex.about-us-text-containter{
    flex-direction: column;
  }

  .img-about{
    width: 30%;
    position: absolute;
    left: 0;
  }

  .container-img-team{
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }
  .team-personas{
    margin-top: 20px;
  }

}

@media (min-width: 701px) and (max-width: 1050px){
  .header-container{
    width: 100vw;
    height: 100vh ;
  }

  .why-text-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .pro-containers{
    width: 100vw;
    margin-top: 80px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    
  }

  .circle-1{
    z-index: 4;
    position: absolute;
    left: 63%;
    bottom: 65%;
    width: 60px;
    height: 60px;
    background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
    -webkit-transform: rotate(-14.45deg);
            transform: rotate(-14.45deg);
    border-radius: 50%;
    -webkit-animation: c1 25s ease-in-out infinite;
            animation: c1 25s ease-in-out infinite;
  }
  
  .circle-2{
    z-index: 4;
    position: absolute;
    left: 40%;
    bottom: 80%;
    width: 80px;
    height: 80px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    -webkit-transform: rotate(144.17deg);
            transform: rotate(144.17deg);
    border-radius: 50%;
    -webkit-animation: c2 15s ease-in-out infinite;
            animation: c2 15s ease-in-out infinite;
  }
  .circle-3{
    z-index: 4;
    position: absolute;
    left: 3%;
    bottom: 75%;
    width: 120px;
    height:120px;
    background: linear-gradient(44.63deg, #4158D0 9.23%, #C850C0 47.04%, #FFCC70 89%);
    -webkit-transform: rotate(-14.45deg);
            transform: rotate(-14.45deg);
    border-radius: 50%;
  }
  
  .circle-4{
    z-index: 4;
    position: absolute;
    left: 79%;
    bottom: 55%;
    width: 143px;
    height: 143px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    -webkit-transform: rotate(144.17deg);
            transform: rotate(144.17deg);
    border-radius: 50%;
  }
  .circle-5{
    z-index: 4;
    position: absolute;
    left: 29%;
    bottom: 90%;
    width: 50px;
    height: 50px;
    background: linear-gradient(44.63deg, #0093E9 9.23%, #80D0C7 89%);
    -webkit-transform: rotate(144.17deg);
            transform: rotate(144.17deg);
    border-radius: 50%;
  }

  .btn-menu{
    background: none;
    border: none;
    position: absolute;
    left: 89%;
    z-index: 1;
    padding-top: 3px;
  }

  .flex.about-us-text-containter{
    flex-direction: column;
  }

  .circle-about{
    z-index:2;
    position: absolute;
    left: 0;
    top:-5%;
  }

  .about-us-container-title{
    font-size: 90px;
    line-height: 100px;
    text-align: center;
    font-weight: 800;
    margin-top:40%;
    margin-left: 0;
  }

  .title-section {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  .about-us-paragraph{
    z-index: 20;
    width: 100vw;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 15px;
    line-height: 35px;
    text-align: center;
    margin-top: 15%;
    margin-bottom: 10%;
  }

  .flex.about-us-text-containter{
    flex-direction: column;
  }

  .img-about{
    width: 30%;
    position: absolute;
    left: 0;
  }

  .about-us-container{
    width: 100vw;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }

  .social-media-container{
    padding: 0px 20px 0px;
    font-size: 14px;
    width: 100%;
    height: 100px;
  }

  .navbar-container{
    justify-content: space-between;
    padding-left: 7%;
    padding-right: 15%;
    align-items: center;
    width: 100%;
    height: 7%;
  }

  .background-scroll{
    background: rgba(73, 73, 73, 0.19);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.27 );
    backdrop-filter: blur( 8.5px );
    -webkit-backdrop-filter: blur( 8.5px );
    width: 100%;
    height: 7%;
    position: fixed;
    transition:  0.5s all ease-in-out ;
  }

  .services-mobile-container{
    width: 100vw;
    background-color:#221E3F;
    padding-bottom: 20%;
    padding-top: 5%;
  }

  .icon-menu{
    width: 33px;
    height: 33px;
  }

  .option-menu-container{
    position: absolute;
    top: -105px;
    padding: 80px 0px 70px;
    left: 0px;
    width: 100vw;
    height: 50vh;
    background: rgba( 0, 0, 0, 0.7 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 30px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    -webkit-clip-path: circle(70.0% at 50% 0);
            clip-path: circle(70.0% at 50% 0);
  }

  .color-white{
    color: white;
  }

  .navbar-options{
    font-size: 26px;
  }

  .flex.navbar.poppins-font{
    padding-right: 10%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .text-slogan-container{
    z-index: 30;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20%;
  }

  .txt-slogan{
    font-size: 79px;
    line-height: 85px;
    color: white;
    font-weight: 400;
    width: 560px;
    height: 290px;
    text-align: center;
  }

  .services-totalcard-container{
    width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-show-more{
    background: none;
    border: none;
    color: white;
    margin-top: 13px;
    font-weight: 700;
  }

  .btn-menu{
    background: none;
    border: none;
  }

  .card-services-container{
    margin-left: 15px;
    margin-right: 15px;
  }

  .input-container{
    width: 100vw;
    height: 100%;
    flex-direction: column;
  }

  .contact-us-container{
    width: 100vw;
    height: 1050px;
    padding-bottom: 55px;
  }

  .input-mail{
    background:none;
    border: none;
    outline: none;
    border-bottom: 1px solid #00BDF9;
    margin-bottom: 20px;
    color: rgba(255, 255, 255);
    margin-right: 24px;
  }
  
  .input-message{
    width: 639px; 
    height: 251px;
    border: 1px solid #00BDF9;
    background:none;
    border-radius: 25px;
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center;
    margin-right: 24px;
  }

  .contact-us-container .flex.justify-content-center{
    display: flex;
    flex-direction: column;
  }

  .flex.input-container.align-items-end{
    align-items: center;
  }

  .flex .input-mail{
    margin-left: 0px;
    margin-top: 20px;
  }

  .flex .input-message{
    margin-left: 0px;
  }

  .btn-countries {
    font-weight: 500;
    font-size: 26px;
    background: none;
    border: none;
  }

  .address-text{
    font-size: 16px;
    font-weight: 400;
    color: white;
  }
  .icon-address{
    width: 20px;
    height: 20px;
  }

  .address-container{
    margin-top: 20px;
    width: 650px;
    height: 100px;
    border: 1px solid #02C6F8;
    border-radius: 19px;
    display: flex;
    justify-content: center;
      
  }
  .margin-top-15{
    margin-top: 5px;
  }
  .btn-countries-container{
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .btn-countries:focus{
    cursor: pointer;
    color: white;
    border-bottom: 3px solid #00BDF9;
  }

  .flex.align-items-center.margin-top-15{
    flex-direction: row;
    padding-left: 13px;
  }

  .btn-countries{
   margin-left: 13px;
  }

  .margin-bottom-15{
    margin-bottom: 10px;
  }

  .btn-countries-container{
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .button{
    width: 143px;
    height: 50px;
    background: #02C6F8;
    border-radius: 50px;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 19px;
  }


  .title-section {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 29px;
    text-align: center;
  }

  .about-us-paragraph{
    width: 700px;
    height: 50%;
    font-size: 17px;
    line-height: 23px;
    text-align:center;
  }

  .container-img-team{
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }
  .team-personas{
    margin-top: 20px;
  }

  .coworkers{
    font-size: 40px;
  }
}

@media  (max-width: 370px){


  .about-us-container{
    width: 100vw;
    height: 100%;
    padding-top: 24px;
  }
  .txt-slogan{
    font-size: 41px;
    line-height: 50px;
    color: white;
    font-weight: 400;
    width: 300px;
    height: 174px;
    text-align: center;
    padding-top: 10%;
    margin-bottom: 5%;
  }

  .about-us-container-title{
    position:absolute;
    top: 11%;
    left: 23%;
    font-size: 40px;
    line-height: 100px;
    text-align: center;
    font-weight: 800;
    margin-left: 0;
  }

  .about-us-paragraph{
    z-index: 23;
    width: 100vw;
    height: 100%;
    font-size: 14px;
    line-height: 25px;
    padding: 7% 3% 0;
    text-align: center;
  }
}

@media (min-width: 1022px) and (max-width: 1050px){
  .about-us-container-title{
    font-size: 140px;
    line-height: 100px;
    text-align: center;
    font-weight: 800;
    margin-top:40%;
    margin-left: 0;
  }
  .about-us-paragraph{
    z-index: 20;
    width: 100vw;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 1px;
    line-height: 40px;
    text-align: center;
    margin-top: 45%;
    margin-bottom: 10%;
  }
}


.btnSubmit{
  margin-bottom:10px
}
